import React from "react"

// Components
import Seo from "components/seo"
import Hero from "components/hero"

// Page Sections
import ThankYou from "./screens/pph-2023/thank-you"

const FormThanks = ({ location }) => {
  return (
    <React.Fragment>
      <Hero pageTitle="Thank You" />
      {(!location.state)
        ?
        <ThankYou />
        :
        <ThankYou
          first_name={location.state.first_name}
        />
      }
    </React.Fragment>
  )
}
export default FormThanks

export const Head = () => (
  <Seo
    title="Thank You"
  >
    <script type="text/javascript"
      //eslint-disable-next-line no-template-curly-in-string
      src="https://tr.contextweb.com/bh/at?cv=505608&t=scr&us_privacy=${us_privacy}"
    />
  </Seo>
)
